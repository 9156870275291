



























import { APP_META } from '@/consts'
import Vue from 'vue'
import { MetaInfo } from 'vue-meta'
import { mapActions } from 'vuex'
export default Vue.extend({
	name: 'PaymentCallback',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `InfoPage | ${APP_META.title}`,
		}
	},
	created() {
		this.resetCurrentOrder()
	},
	methods: {
		...mapActions('carts', ['resetCurrentOrder']),
	},
})
